import React from 'react'
import "./item.css"
import { ItemType } from '../../Models/ItemModel';
import { Link } from 'react-router-dom';
import { ProductQuantity } from '../ProductQuantity/ProductQuantity';

// interface itemProps {
//     key: number;
//     id:number;
//     image: string;
//     name: string;
//     old_price: number;
//     new_price: number;
// }


export const Item = ({id, image, name, old_price, new_price} : ItemType) => {
  return (
    <div className='item'>
        {/* <Link to={`/product/${id}`}> <img onClick={(event) => window.scrollTo(0,0)} src={image.replace('/src', '')} alt="" /> </Link> */}
        <Link to={`/product/${id}`}> <img onClick={(event) => window.scrollTo(0,0)} src={image} alt="" /> </Link>
        <p>{name}</p>
        <div className="item-prices">  
            {old_price > 0 && (
            <div className="item_price">
                <div className='shekel-price-old'>{'\u20AA'}</div>
                <div className="item-price-old">
                    {old_price}
                </div>
            </div>
            )}
            <div className="item_price">
                <div className='shekel-price-new'>{'\u20AA'}</div> 
                <div className="item-price-new">
                {new_price}
                </div>
            </div>
        </div>
        {/* <div className="add-to-cart">
            <button className='addToCartButton'> הוסף לסל</button>
        </div> */}
        <ProductQuantity productId = {id}/>
    </div>
  )
}
