
import alcohol_banner from './banners/alcohol_banner.png'
import ice_banner from './banners/ice_banner3.png'
import soft_drink_banner from './banners/soft_drink_banner_2.png'
import food_snacks_banner from './banners/food_snacks.png'
import disposable_products_banner from './banners/disposable_banner.png'
import clean_products_banner from './banners/clean_banner.png'
import related_products_banner from './banners/related_banner1.png'
import equipment_rental_banner from './banners/rental_banner.png'
import sale_banner from './banners/sale_banner.gif'

let all_categories = [
  {
    category: "אלכוהול",
    banner: alcohol_banner ,
    subCategories: ["בירה", "יין", "וודקה", "ג'ין", "ערק", "טקילה" , "ויסקי", "כל המוצרים - אלכוהול"]
  },
  {
    category: "קרח",
    banner: ice_banner,
    subCategories: ["כל המוצרים - קרח"]
  },
  {
    category: "אוכל ונשנושים",
    banner: food_snacks_banner,
    subCategories: ["ירקות","פירות", "נשנושים","קישוטים לקוקטייל","כל המוצרים - אוכל ונשנושים"]
  },
  {
    category: "חד פעמי",
    banner: disposable_products_banner,
    subCategories: ["כל המוצרים - חד פעמי"]
  },
  
  {
    category: "מוצרים נלווים",
    banner: related_products_banner,
    subCategories: ["דברים גדולים","כוסות ומאגים","דברי קישוט","כלים לברמן","כל המוצרים - מוצרים נלווים"]
  },
  {
    category: "שתייה קלה",
    banner: soft_drink_banner,
    subCategories: ["מים","משקאות אנרגיה","לא מוגז","מוגז","עם הקוקטייל","כל המוצרים - שתייה קלה"]
  },
  {
    category: "השכרת ציוד",
    banner: equipment_rental_banner,
    subCategories: ["בקרוב","בקרוב","בקרוב","בקרוב","בקרוב","בקרוב","בקרוב",]
  },
  {
    category: "מוצרי נקיון",
    banner: clean_products_banner,
    subCategories: ["כל המוצרים - מוצרי נקיון"]
  },
  {
    category: "מבצעים",
    banner: sale_banner,
    subCategories: ["כל המוצרים - מבצעים"]
  },
];


export default all_categories;
