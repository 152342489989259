import React, { useContext, useEffect, useState } from 'react'
import './CSS/ProductsSearch.css'
import { ShopContext } from '../Context/ShopContext'
import { categoryType } from '../Models/CategoryModel';
import dropdown_icon from '../components/Assets/dropdown_icon.png'
import { Item } from '../components/Item/Item';
import { ItemType } from '../Models/ItemModel';
import { useSearchParams } from 'react-router-dom';
import { searchProducts } from '../Services/productServices';

export const ProductsSearch = () => {

  const [searchResults, setSearchResults] = useState<ItemType[]>([]);
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query');

  useEffect(() => {
    const fetchSearchResult = async () =>{
      if (query) {
        const results = await searchProducts(query);
        setSearchResults(results);
      }
    }
    fetchSearchResult();
  }, [query]);
  
  return (
    <div className='shop-category'>
      <div className="shopcategory-indexSort">
        <div className="shopcategory-sort">
          <img src={dropdown_icon} alt="" /> סדר לפי 
        </div>
        {/* <p>
          <span>מציג 1-12</span> מתוך 36 מוצרים
        </p> */}
      </div>
      <div className="shopcategory-products">
        {searchResults && 
        (searchResults.map((item,i)=>{
          return <Item key={i} 
              id={item.id} 
              name={item.name} 
              image={item.image} 
              new_price={item.new_price} 
              old_price={item.old_price}/>
          })
        )}
      
        {searchResults.length === 0 &&
        (
          <h1> לא נמצאו מוצרים עבור : {query} </h1>
        )}
      </div>
    </div>
  )
}