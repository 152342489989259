import React from 'react'
import './SubCategoriesNavbar.css'
import { useNavigate } from 'react-router-dom';
import { pink } from '@mui/material/colors';


interface SubCategoriesNavbarProps{
    category: string,
    subCategories: string[]
}

export const SubCategoriesNavbar = ({category, subCategories}: SubCategoriesNavbarProps) => {

    const navigate = useNavigate();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, path: string) => {
        event.preventDefault();
        navigate(path);
    };

    // const colorsArray = ['red', 'green', 'blue', 'yellow', 'pink'];
    // // const buttonColor = 'blue'; // This would be determined by your page logic
    // const buttons = document.querySelectorAll('.subCategory-but');


    // // Generate a random index between 0 and the length of the array minus 1
    // const randomIndex = Math.floor(Math.random() * colorsArray.length);

    // // Use the random index to select a color from the array
    // const buttonColor = colorsArray[randomIndex];

    // buttons.forEach(button => {
    //     button.classList.add(`color-${buttonColor}`);
    // });

    return (
        <div className='sub-categories'>
            {subCategories.map
                ((subCategory, index)=>{
                    let classname = "";
                    if (subCategory === category){
                         classname = "clicked_subCategory-but";
                    }else{
                         classname = "subCategory-but";
                    }
                    return <button onClick={(e) => handleClick(e, `/${subCategory}`)} className={classname} key={index}> {subCategory.includes("כל המוצרים") ? "כל המוצרים": subCategory}</button>
                }
                )
            }
        </div>
    )}
