import React, { useContext, useEffect, useState } from "react";
import {TextField, Button, Typography, Snackbar, Alert, AlertColor} from "@mui/material";
import './CSS/loginSignup.css'
import { Link, useNavigate } from "react-router-dom";
import { getUserInformation, signinUser, signupUser } from "../Services/authServices";
import { SignupCredentials } from "../Models/UserModel";
import { styled } from '@mui/material/styles';
import { ShopContext } from "../Context/ShopContext";

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    right: 0,
    left: 'auto',
    transformOrigin: 'top right',
  },
}));


export const LoginSignup = () => {

 
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [isLogin, setIsLogin] = useState(true);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("error");

  const [emailError, setEmailError] = useState("");

  // Clear form fields when isSignUp changes
  useEffect(() => {
      setEmail('');
      setPassword('');
      setRePassword('');
      setFirstName('');
      setLastName('');
  }, [isLogin]);

  // get context values
  const shopContext = useContext(ShopContext);
  const cartItems = shopContext[1];
  const login = shopContext[7];
  
  const navigate = useNavigate();

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    setEmail(newEmail);

    if (!validateEmail(newEmail)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleRePasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRePassword(event.target.value);
  };

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const handleLoginSubmit = async (event: React.FormEvent<HTMLFormElement>) => {  
    event.preventDefault();
    if (emailError) {
      setSnackbarMessage(emailError);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
    localStorage.setItem('cart', JSON.stringify(cartItems));
    try {
      await (signinUser({email,password}));
      //get user information
      const userData = await (getUserInformation());

      //update context
      login(userData);
      console.log(userData);
      console.log("Login successful");

      const storedCart = localStorage.getItem('cart');
      if (storedCart) {
        navigate('/payment');
      } else{
        //navigate to the right page
        console.log("Navigating to role page:", userData.role);
        navigate(`/${userData.role}`);
      }

      

    } catch (error) {
      console.error("Login failed:", error);
      setSnackbarMessage("Login failed");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSignupSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    if (password !== rePassword) {
      setSnackbarMessage("Passwords do not match");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
    if (emailError) {
      setSnackbarMessage(emailError);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
    try {
      
      const signupCredentials: SignupCredentials = {
        firstName : firstName,
        lastName : lastName,
        email: email,
        password: password,
        repassword: rePassword

      }
      await (signupUser(signupCredentials));
      
      // Clear form fields
      setEmail('');
      setPassword('');
      setRePassword('');
      setFirstName('');
      setLastName('');
      setIsLogin(true);

      await (signinUser({email,password}));
      //get user information
      const userData = await (getUserInformation());
      console.log(userData)

      //update context
      login(userData);
      
      //navigate to the right page
      console.log("Navigating to role page:", userData.role);
      navigate(`/${userData.role}`);
      
      console.log("Sign up successful");

    } catch (error) {
      console.error("Sign up failed:", error);
      setSnackbarMessage("Sign up failed");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleForgotPassword = () =>{
    navigate("/forgotPassword");
  }
  
  return (
    <>
      <form
        className="loginForm"
        onSubmit={isLogin ? handleLoginSubmit : handleSignupSubmit}
      >
        <div className={`loginContainer ${!isLogin ? "signUp" : ""}`}>
          <div className="loginLabelDiv">
            <Typography className="loginLabel" variant="h4" style={{ fontWeight: 'bold' }}>
              {isLogin ? "כניסה לחשבון" : "רוצה להירשם"}
            </Typography> 
          </div>
          <div className={`textfieldDiv ${!isLogin?  "signUp" : ""}`}>

            {!isLogin && (
              <>
                <CustomTextField
                  InputLabelProps={{
                    style: { fontSize: 20 , color:"white"},
                  }}
                  InputProps={{
                    style: { textAlign: 'right' , color: "white"},
                    inputProps: {
                      dir: 'rtl',
                    },
                  }}
                  className="textfield"
                  id="firstName"
                  label="שם פרטי"
                  variant="standard"
                  color="warning"
                  onChange={handleFirstNameChange}
                  value = {firstName}
                  required
                />
                <br />
                <CustomTextField
                  InputLabelProps={{
                    style: { fontSize: 20 , color:"white"},
                  }}
                  InputProps={{
                    style: { textAlign: 'right' , color: "white"},
                    inputProps: {
                      dir: 'rtl',
                    },
                  }}
                  className="textfield"
                  id="lastName"
                  label="שם משפחה"
                  variant="standard"
                  color="warning"
                  onChange={handleLastNameChange}
                  value = {lastName}
                  required
                />
                <br />
              </>
            )}
            <CustomTextField
              InputLabelProps={{
                style: { fontSize: 20 , color:"white"},
              }}
              InputProps={{
                style: { textAlign: 'right' , color: "white"},
              }}
              className="textfield"
              id="email"
              label="מייל"
              variant="standard"
              color="warning"
              onChange={handleEmailChange}
              error={!!emailError}
              helperText={emailError}
              required
              value = {email}
            />
            <br />
            <CustomTextField
              InputLabelProps={{
                style: { fontSize: 20 , color:"white"},
              }}
              InputProps={{
                style: { textAlign: 'right' , color: "white"},
              }}
              className="textfield"
              id="password"
              label="סיסמה"
              variant="standard"
              color="warning"
              type="password"
              onChange={handlePasswordChange}
              value = {password}
              required
            />
            <br />
            {!isLogin && (
              <>
                <CustomTextField
                  InputLabelProps={{
                    style: { fontSize: 20 , color:"white"},
                  }}
                  InputProps={{
                    style: { textAlign: 'right' , color: "white"},
                  }}
                  className="textfield"
                  id="rePassword"
                  label="אימות סיסמה"
                  variant="standard"
                  color="warning"
                  type="password"
                  onChange={handleRePasswordChange}
                  value = {rePassword}
                  required
                />
                <br />
              </>
            )}
          </div>

          <div className="btnDiv">
            <Button
              className="formBtn"
              type="submit"
              variant="contained"
              color={isLogin ? "primary" : "success"}
            >
              {isLogin ? "כניסה" : "יצירת חשבון"}
            </Button>

            {isLogin ? (
              <>
                <Typography
                  className="forgetPassword"
                  component={Link}
                  to={"./"}
                  variant="caption"
                  color="white"
                  fontSize={16}
                  onClick={handleForgotPassword}
                >
                  שכחת סיסמה?
                </Typography>
                <hr className="hr" />
                <Button
                  className="formBtn"
                  onClick={() => setIsLogin(false)}
                  variant="contained"
                  color="success"
                  sx={{width:"60%"}}
                >
                  !אין לך חשבון? הירשם כאן
                </Button>
              </>
            ) : (
              <>
                <Typography
                  className="forgetPassword"
                  component="button"
                  onClick={() => setIsLogin(true)}
                  variant="caption"
                  color="white"
                  fontSize={18}
                  style={{
                    cursor: "pointer",
                    background: "#5ebef7",
                    border: "none",
                    padding: 5,
                    textDecoration:"underline"
                  }}
                >
                 כבר משתמש שלנו?, התחבר
                </Typography>
              </>
            )}
          </div>
        </div>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
