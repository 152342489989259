import React, { useContext } from 'react'
import './productDisplay.css'
import star_icon from "../Assets/star_icon.png";
import star_dull_icon from "../Assets/star_dull_icon.png";
import { ItemType } from '../../Models/ItemModel';
import { ShopContext } from '../../Context/ShopContext';
import { ProductQuantity } from '../ProductQuantity/ProductQuantity';
// import logo from '../Assets/logo_new17.png'
//import logo from '../Assets/logo_for_productPage.png'
import logo from '../Assets/logo_for_productdisplay.svg';

export const ProductDisplay = ({product}:{product?:ItemType}) => {
    const shopContext = useContext(ShopContext);
    const addToCart = shopContext[2];

    if (!product) {
        return <div>No product available</div>;
    }else{
        return (
        <div className="productdisplay">
        <div>
            <div className="productdisplay-left">
                <h1>{product.name}</h1>
                {/* <div className="productdisplay-left-stars">
                    <img src={star_icon} alt="" />
                    <img src={star_icon} alt="" />
                    <img src={star_icon} alt="" />
                    <img src={star_icon} alt="" />
                    <img src={star_dull_icon} alt="" />
                    <p>(122)</p>
                </div> */}
                <div className="productdisplay-left-prices">
                {product.old_price > 0 && (<div className="productdisplay-left-price-old">{'\u20AA'}{product.old_price}</div>)}
                    <div className="productdisplay-left-price-new">{'\u20AA'}{product.new_price}</div>
                </div>
                {/* <button onClick={()=>{addToCart(product.id as number)}}>הוסף לסל</button> */}
                <ProductQuantity productId={product.id}/>
                <div className="product-information">
                    <h3>מידע על המוצר</h3>
                    <p>{product.information}</p>  
                </div>
                <p className="productdisplay-left-category"><span>קטגוריה:</span> {product.category} {product.subCategory? `,${product.subCategory}`: ''}</p>
                {/* <img className='logo' src={logo} alt="" /> */}
                {/* <div className="container">
                    <img src={logo} alt="Truck Logo" className="logo" />
                </div> */}
            </div>
            <div className="container">
                <img src={logo} alt="Truck Logo" className="logo" />
            </div>
            </div>
            <div className='black'></div>
            <div className='blue'></div>
            <div className='black'></div>
            <div className='blue'></div>
            <div className='black'></div>
            <div className='blue'></div>
            <div className="productdisplay-right">
                <div className="productdisplay-img">
                    <img className="productdisplay-main-img" src={product.image.replace('/src', '')} alt="img" />   
                </div>
                <p> התמונה להמחשה בלבד*</p>
            </div>
            <div className='black'></div>
            <div className='blue1'></div>
            <div className='black'></div>
            <div className='blue1'></div>
            <div className='black'></div>
            <div className='blue1'></div>
        </div>
        );
    }
  };