import React, { useState } from "react";
import "./TranBankPayboxPopup.css";

interface TranBankPayboxPopupProps {
  onClose: () => void; // Function to handle closing the popup
  onSendCart: () => void; // Function to handle sending the cart
}

const TranBankPayboxPopup: React.FC<TranBankPayboxPopupProps> = ({
  onClose,
  onSendCart,
}) => {
  const [confirmationMessage, setConfirmationMessage] = useState<string>("");

  const handleSendCart = () => {
    setConfirmationMessage("העגלה נשלחה לאישור ותאושר לאחר בדיקת התשלום.");
    onSendCart(); // Call the parent function to handle cart confirmation
  };

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <h1 className="popup-title"> לקוח יקר, שים לב!</h1>
        <h2 className="popup-h2"> ההזמנה תאושר רק אחרי שליחת אסמכתא של ההעברה או של ה PAYBOX</h2>
        <h2 className="popup-h2">לוואטסאפ שלנו במספר<br/> 050-8285818</h2>
        <div className="transfer-paybox-container">
            <div>
                <h3> להעברה בנקאית </h3>
                {/* <h4 className="popup-text">פרטי החשבון שלנו:</h4> */}
                <p className="popup-text">שם הבנק: מרכנתיל</p>
                <p className="popup-text">שם בעל החשבון: הניידים</p>
                <p className="popup-text">מספר סניף: 756</p>
                <p className="popup-text">מספר חשבון: 1011985942</p>
                </div>
            <div>
                <h3> לתשלום בעזרת PAYBOX</h3>
                <p className="popup-text">050-8285818</p>
            </div>

        </div>
        
        {confirmationMessage && (
          <p className="popup-confirmation-message">{confirmationMessage}</p>
        )}

        <div className="popup-button-container">
          <button className="popup-button popup-button-send" onClick={handleSendCart}>
             הבנתי! שלח לאישור 
          </button>
          <button className="popup-button popup-button-close" onClick={onClose}>
            בטל
          </button>
        </div>
      </div>
    </div>
  );
};

export default TranBankPayboxPopup;
