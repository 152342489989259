import React, { useContext } from 'react'
import './CSS/shopCategory.css'
// import all_product from '../components/Assets/all_product'
import { ShopContext } from '../Context/ShopContext'
import { categoryType } from '../Models/CategoryModel';
import dropdown_icon from '../components/Assets/dropdown_icon.png'
import { Item } from '../components/Item/Item';
import { SubCategoriesNavbar } from '../components/SubCategoriesNavbar/SubCategoriesNavbar';
import all_categories from '../components/Assets/all_categories';
import {extractCategory, getCategoryBySubcategory} from '../utils/utils'

export const ShopCategory = ({banner, category, subCategories} : categoryType) => {
  const shopContext = useContext(ShopContext);
  const allProducts = shopContext[0];

  const getOtherSubCategories = (subCategoryName: string) => {
    for (const category of all_categories) {
        if (category.subCategories.includes(subCategoryName)) {
            return category.subCategories;
        }
    }
    return [];
};
  

  return (
    <div className='shop-category'>
      <img className="shopcategory-banner" src={banner} alt="" />
      <SubCategoriesNavbar category = {category} subCategories={getOtherSubCategories(category)}/>
      {subCategories.length > 0 && 
      (<div className="subCategories_navbar">
        <SubCategoriesNavbar category="" subCategories={subCategories}/>
      </div>)} 
      {/* <div className="shopcategory-indexSort">
        <div className="shopcategory-sort">
          <img src={dropdown_icon} alt="" /> סדר לפי 
        </div>
        <p>
          <span>מציג 1-12</span> מתוך 36 מוצרים
        </p>
      </div> */}
      <div className="shopcategory-allProducts">
        {allProducts.map((item,i)=>{
          if (category.includes("כל המוצרים")){
           category = extractCategory(category);

          }
          if (category === item.category || category === item.subCategory){
              return <Item key={i} 
              id={item.id} 
              name={item.name} 
              image={item.image} 
              new_price={item.new_price} 
              old_price={item.old_price}/>
          }
          else{
            return null;
          }
          })} 
      </div>
      {/* <div className="shopcategory-loadmore">
        Explore More
      </div> */}
    </div>
  )
}

