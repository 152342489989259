import React, { useEffect, useState } from 'react'
import dropdown_icon from '../components/Assets/dropdown_icon.png'
import { Item } from '../components/Item/Item';
import { ItemType } from '../Models/ItemModel';
import { getnewProducts} from '../Services/productServices';
import './CSS/NewProducts.css'

export const NewProducts = () => {

  const [newProducts, setNewProducts] = useState<ItemType[]>([]);

  useEffect(() => {
    const fetchNewProducts = async() => {
      const products = await getnewProducts();
      setNewProducts(products);
    }
    fetchNewProducts();
  }, []);

   
  return (
    <>
    <div className="headline">
      <h1>המוצרים החדשים שלנו</h1>
      <hr/>
    </div>
    <div className='shop-category'>
      {/* <div className="shopcategory-indexSort">
        <div className="shopcategory-sort">
          <img src={dropdown_icon} alt="" /> סדר לפי 
        </div>
        <p>
          <span>מציג 1-12</span> מתוך 36 מוצרים
        </p>
      </div> */}
      <div className="shopcategory-products">
        {newProducts.map((item,i)=>{
          return <Item key={i} 
              id={item.id} 
              name={item.name} 
              image={item.image} 
              new_price={item.new_price} 
              old_price={item.old_price}/>
          }
        )}
      </div>
    </div>
  </>
  )
}