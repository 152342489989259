import React, { useState, useEffect } from "react";
// import { FaArrowUp } from "react-icons/fa";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "./BackToTopButton.css";
// import { FormatSize } from "@mui/icons-material";

const BackToTopButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  // Toggle visibility based on scroll position
  const toggleVisibility = () => {
    if (window.scrollY > 1000) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <div className="back-to-top">
       
      {isVisible && (
        <div className="back-to-top-container">
             <a
                href="https://wa.me/+972508285818"
                target="_blank"
                rel="noopener noreferrer"
                className="whatsapp-icon-link"
            >
                <WhatsAppIcon style={{ fontSize: "45px" }} />
            </a>
            <button onClick={scrollToTop} className="back-to-top-button">
                <KeyboardArrowUpIcon style={{ fontSize: "45px" }} />
            </button>
            <p>חזרה למעלה</p>
        </div>
      )}
    </div>
  );
};

export default BackToTopButton;
